import React from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from "@core/scripts/helper/jsh-module";
import WishlistHeart from '@core/scripts/components/wishlist/heart';

do {

    const {moduleScope, config} = JshModule('teaser/add-to-wishlist');

    const {articleNumber, promotion, shopId, digistyleId, itemId, formattedPrice} = config;

    createRoot(moduleScope).render(
        <WishlistHeart
            className={"content-platform__add-to-wishlist-heart content-platform__add-to-wishlist-heart__product-slider"}
            articleNumber={articleNumber}
            promotion={promotion}
            shopId={shopId}
            digistyleId={digistyleId}
            itemId={itemId}
            origin={"Contentful Product Slider hbs version"}
            articlePrice={formattedPrice}
        />
    );
} while (document.querySelector('[jsh-module="teaser/add-to-wishlist"]') !== null);
